import React from 'react';
import styled from 'styled-components';

const Footer = () => {
    return(
        <StyledFooter>
            <p>Copyright © Axoum Dev</p>
        </StyledFooter>
    )
}

const StyledFooter = styled.div`
    background: #1d1b1b;
    p {
        text-align: center;
    }
`;

export default Footer;
