import React from 'react';
import ContentLoader from "react-content-loader";

const ProjectDetailsLoader = (props) => {
    return ( <ContentLoader 
    speed={2}
    width={900}
    height={700}
    viewBox="0 0 400 460"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="10" y="19" rx="2" ry="2" width="140" height="10" /> 
    <rect x="12" y="34" rx="2" ry="2" width="87" height="6" /> 
    <rect x="0" y="60" rx="2" ry="2" width="271" height="335" /> 
    <rect x="276" y="61" rx="0" ry="0" width="108" height="65" /> 
    <rect x="276" y="135" rx="0" ry="0" width="108" height="65" /> 
    <rect x="278" y="285" rx="0" ry="0" width="108" height="65" /> 
    <rect x="278" y="209" rx="0" ry="0" width="108" height="65" /> 
    <rect x="4" y="419" rx="0" ry="0" width="398" height="125" />
  </ContentLoader>
) ;
}
 
export default ProjectDetailsLoader;
