import React from 'react';
import styled from "styled-components";
import { Link, useLocation } from 'react-router-dom';
import ProjectDetails from './ProjectDetails';
import useFetch from '../services/useFetch';
import { toast } from 'react-toastify';

const Project = ({project}) => {

  const featureImageElements = useFetch(`/wp-json/wp/v2/media/${project.featured_media}`)
      
  const imageUrl = featureImageElements.source_url;

  const loadVideoHandler = (e) => {
    toast.info("La vidéo de présentation n'est pas encore disponible");
  } 

  // Get the current location
  const location = useLocation();
  let pathSlug = "/portfolio";
  if (location.pathname !== pathSlug) {
     pathSlug = location.pathname.split('/')[2];
  }
    
return (
      <>
        <StyledProject className='card'>
          <div className='card_left'>
            <img src={imageUrl} alt="image_projet" />
          </div>
          <div className='card_right'>
            <h2>{project.title.rendered}</h2>
            <div className='card_right__details'>
              <div className='card_right__review' dangerouslySetInnerHTML={{__html: project.excerpt.rendered }}>
              </div>
              <div className='card_right__button'>
                {/* <Link to={{
                  pathname: `/portfolio/${project.slug}`,
                  medias: {mediaId: "project.featured_media"}
                }}> */}
                <Link to={`/portfolio/${project.slug}?idMedia=${project.featured_media}`}>
                  <button><i className="fa fa-list"></i>Détails</button>
                </Link>
                <button onClick={loadVideoHandler}><i className="fa fa-play"></i>Présentation</button>
              </div>
            </div>
          </div>
        </StyledProject>  
        
        { pathSlug !== "/portfolio" && <ProjectDetails 
          pathSlug={pathSlug}
        /> } 

      </>

  );
};

const StyledProject = styled.div` 
  width: 500px;
  height: 300px;
  background: black;
  overflow: hidden;
  margin-bottom: 5rem;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  box-shadow: 0px 10px 10px 3px rgba(0, 0, 0, 0.15);

 .card_left {
  width: 40%;
  float: left;
  overflow: hidden;
  background: transparent;
}
.card_left img {
  object-fit: cover;
  object-position: 0 0;
  border-radius: 10px 0 0 10px;
  -webkit-border-radius: 10px 0 0 10px;
  -moz-border-radius: 10px 0 0 10px;
  position: relative;
}
.card_right {
  width: 60%;
  float: left;
  background: #000000;
  border-radius: 0 10px 10px 0;
  -webkit-border-radius: 0 10px 10px 0;
  -moz-border-radius: 0 10px 10px 0;
}
.card_right h2 {
  color: white;
  font-weight: 400;
  text-align: left;
  font-size: 1.9rem;
  margin: 20px 0 0 0;
  padding: 0 0 0 20px;
  letter-spacing: 1px;
}
.card_right__details ul {
  list-style-type: none;
  padding: 0 0 0 40px;
  margin: 10px 0 0 0;
}
.card_right__details ul li {
  display: inline;
  color: #E38C4D;
  font-weight: 400;
  font-size: 14px;
  padding: 0 40px 0 0;
}
.card_right__review p {
  color: white;
  font-size: 12px;
  padding: 0 20px 0 20px;
  letter-spacing: 1px;
  margin: 10px 0 10px 0;
  line-height: 20px;
}
.card_right__review button {
  text-decoration: none;
  font-size: 14px;
  padding: 0 0 0 40px;
  color: #E38C4D;
  margin: 0;
}
.card_right__button {
  padding: 0 0 0 40px;
  margin-top: 30px;
}
.card_right__button button {
  color: #E38C4D;
  text-decoration: none;
  border: 2px solid #E38C4D;
  margin-right: 10px;
  padding: 10px;
  font-size: 10px;
  border-radius: 5px;
  transition-property: all;
  transition-duration: 0.5s;
}
.card_right__button button:hover {
  color: #000000;
  background-color: #E38C4D;
  cursor: pointer;
  transition-property: all;
  transition-duration: 0.5s;
}

.card_right__button i {
  padding-right: 5px;
}

@media(max-width: 680px) {
  .card_right__button {
    padding: unset;
    margin-top: 16px;
    margin-left: 1rem;
  }
  .card_left img {
    object-position: unset;
  }
}
`;

export default Project;