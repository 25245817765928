import {useEffect, useState} from "react";

export default function useFetch(url) {
    const [data, setData] = useState([]);

    url = `https://wp.axoumdev.com${url}`;
    
    useEffect(() => {
        async function loadData() {
            const response = await fetch(url);
            if(!response.ok) {
                 // oups! something went wrong
                return;
            }
            const projects = await response.json();
            setData(projects);
        }

    loadData();
    }, [url]);
    
    return data;
}