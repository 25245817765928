import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html {
        @media(max-width: 1700px) {
            font-size: 75%;
        }
    }

    body {
        background: #5C946E;
        //background: #1d1b1b;
        font-family: 'Inter', sans-serif;
        overflow-x: hidden;
    }

    button {
        font-weight: bold;
        font-size: 1.1.em;
        cursor: pointer;
        padding: 1rem 2rem;
        border: 3px solid #E38C4D;
        background: transparent;
        color: white;
        transition: all 0.5s ease;
        font-family: 'Inter', sans-serif;
        &:hover {
            background-color: #E38C4D;
            color: white;
        }
    }

    h2 {
        font-weight: lighter;
        font-size: 3.5rem;
        }

    h3 {
        color: white;
        font-size: 1.5rem;
        }

    h4 {
        font-weight: bold;
        font-size: 2rem;
        .arrow {
            float: right;
        }
    }

    a {
        font-size: 1.1 rem;
        color: #E38C4D;
    }

    span {
        font-weight: bold;
        color: #E38C4D;
    }

    p {
        padding: 3rem 0rem;
        color: #ccc;
        font-size: 1.4rem;
        line-height: 150%;
    }

    // Toast element
    .Toastify__toast--info {
        background-color: #5C946E;
        font-size: 1.6rem;
    }
    .Toastify__toast-container--top-left {
        margin-top: 10vh;
        width: fit-content;
    }

`;

export default GlobalStyle;
