import React from "react";
// React
// Animations
import styled from "styled-components";
import { motion } from "framer-motion";
import {
  sliderContainer,
  slider,
  pageAnimation,
  fade,
  lineAnim,
} from "../animation";
import { UseScroll } from "../components/UseScroll";
import Project from "../components/Project";
import useFetch from "../services/useFetch";

const Portfolio = () => {
  const [element, controls] = UseScroll();
  const [element2, controls2] = UseScroll();

  const projects = useFetch("/wp-json/wp/v2/projet?categories=2");
  const projectsApp = useFetch("/wp-json/wp/v2/projet?categories=3");
  const projectsConcept = useFetch("/wp-json/wp/v2/projet?categories=4");
  
  return (
    <Work
      exit="exit"
      variants={pageAnimation}
      initial="hidden"
      animate="show"
      style={{ background: "#fff" }}
    >
      <motion.div variants={sliderContainer}>
        <Frame1 variants={slider} />
        <Frame2 variants={slider} />
        <Frame3 variants={slider} />
        <Frame4 variants={slider} />
      </motion.div>
      <Movie>
        <motion.h2 variants={fade}>Sites web</motion.h2>
        <motion.div variants={lineAnim} className="line"></motion.div>
          <Hide>

            <StyledProjectParent>
              {projects.map(project => ( 
              <Project key={project.id} 
                      project={project}
              />
              ))}
            </StyledProjectParent>

          </Hide>
      </Movie>

      <Hide>
        <Movie
          variants={fade}
          ref={element}
          initial="hidden"
          animate={controls}
        >
          <h2>Applications web et/ou mobile</h2>
          <motion.div variants={lineAnim} className="line"></motion.div>
            <StyledProjectParent>
              {projectsApp.map(project => ( 
              <Project key={project.id} 
                      project={project}
              />
              ))}
            </StyledProjectParent>

        </Movie>
      </Hide>

      <Movie
        variants={fade}
        ref={element2}
        initial="hidden"
        animate={controls2}
      >
        <h2>Conceptions • Audits • Documentations</h2>
        <motion.div variants={lineAnim} className="line"></motion.div>
        <StyledProjectParent>
              {projectsConcept.map(project => ( 
              <Project key={project.id} 
                      project={project}
              />
              ))}
          </StyledProjectParent>

      </Movie>

    </Work>
  );
};

const StyledProjectParent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Work = styled(motion.div)`
  min-height: 100vh;
  overflow: hidden;
  padding: 5rem 10rem;
  h2 {
    padding: 1rem 0rem;
  }
  @media (max-width: 1200px) {
    padding: 2rem 2rem;
  }
`;

const Movie = styled(motion.div)`
  padding-bottom: 10rem;
  .line {
    height: 0.5rem;
    background: #E38C4D;
    margin-bottom: 3rem;
  }
  img {
    width: 100%;
    height: 85vh;
    object-fit: cover;
  }
`;

const Hide = styled.div`
  overflow: hidden;
`;

// FRame animation
const Frame1 = styled(motion.div)`
  position: fixed;
  left: 0;
  top: 10%;
  width: 100%;
  height: 100vh;
  background: #fffebf;
  z-index: 2;
`;

const Frame2 = styled(Frame1)`
  background: #ff8efb;
`;

const Frame3 = styled(Frame1)`
  background: #5c946e;
`;

const Frame4 = styled(Frame1)`
  background:  #E38C4D;
`;

export default Portfolio;
