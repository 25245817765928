import React, {useState} from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import ProjectDetailsLoader from "./loaders/ProjectDetailsLoader";
import useFetch from "../services/useFetch";


const ProjectDetails = ({pathSlug}) => {

  // Loader
  const [loading, setLoading] = useState(true);
  
  //Exit Detail
  const history = useHistory();
  const exitDetailHander = (e) => {
    const element = e.target;
    if (element.classList.contains("shadow")) {
      document.body.style.overflow = "auto";
      history.push("/portfolio");
    }
  };
  
    // Get the params idMedia in url
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let idMedia = urlParams.get('idMedia');
  
    //View Details on project
    const projectDetailsFetch = useFetch(`/wp-json/wp/v2/projet?slug=${pathSlug}`);
    // Get the feature
    const featureImage = useFetch(`/wp-json/wp/v2/media/${idMedia}`).source_url;

    const details = projectDetailsFetch[0];

    Promise.all([projectDetailsFetch, featureImage]).then(values => {
      if(values && details) {
        setLoading(false);
      }
    })

    return (
       <CardShadow className="shadow" onClick={exitDetailHander}>
           <Detail>
            {!loading && <div>
            <Stats>
              <div className="rating">
              <h2 dangerouslySetInnerHTML={{__html: details.title.rendered}}></h2>
              <p><strong>Stack :</strong> {details.acf.stack}  </p>
              </div>
            </Stats>
            {featureImage && <div className="images-container">
              <Media>
                <a href={featureImage} alt="Image du projet" target="_blank" rel="noreferrer">
                  <motion.img
                    layoutId={`${featureImage}`}
                    src={featureImage}
                    alt="Image du projet"
                  />
                </a>
              </Media>
              {<div className="gallery-parent">
                  <div className="gallery">
                      <a target="_blank" href={details.acf.image1.sizes.large} rel="noreferrer">
                        <img src={details.acf.image1.sizes.large} alt="Aperçu 1 du projet" />
                      </a>
                  </div>               
                 <div className="gallery">
                    <a target="_blank" href={details.acf.image2.sizes.large} rel="noreferrer">
                      <img src={details.acf.image2.sizes.large} alt="Aperçu 2 du projet" />
                    </a>
                 </div>
                 <div className="gallery">
                    <a target="_blank" href={details.acf.image3.sizes.large} rel="noreferrer">
                      <img src={details.acf.image3.sizes.large} alt="Aperçu 3 du projet" />
                    </a>
                 </div>
                 <div className="gallery">
                    <a target="_blank" href={details.acf.image4.sizes.large} rel="noreferrer">
                      <img src={details.acf.image4.sizes.large} alt="Aperçu 4 du projet" />
                    </a>
                 </div>
              </div>}
            </div>}
            <Description>
              <div dangerouslySetInnerHTML={{__html: details.content.rendered}}></div>
            </Description>
            </div> }
           {loading && <ProjectDetailsLoader />}
          </Detail> 
        </CardShadow>
    );

}

const CardShadow = styled(motion.div)`
  width: 100%;
  min-height: 100vh;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;

  p, h3 {
    padding: 0.6rem;
    color: rgba(0, 0, 0, 0.8);
  }

  h2 {
    font-size: 3.5rem;
  }

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ff7676;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }

// Gallery
div.images-container {
  width: 100%;
  margin-top: 5rem;
  display: flex;
  justify-content: space-around;
  //flex-wrap: wrap;
}

div.gallery {
  margin: 5px;
  border: 1px solid #ccc;
  //float: left;
  width: 200px;
}

div.gallery:hover {
  border: 1px solid #777;
}

div.gallery img {
  height: 24vh;
}

@media(max-width: 688px) {
      h2 {
        font-size: 2.5rem;
      }
};

`;

const Detail = styled(motion.div)`
  width: 80%;
  border-radius: 1rem;
  padding: 2rem 5rem;
  background: white;
  position: absolute;
  left: 10%;
  color: black;
  z-index: 10;
  img {
    width: 100%;
  }
`;

const Stats = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 2rem;
    height: 2rem;
    display: inline;
  }
  strong {
    color: #E38C4D;
    text-decoration: underline;
  }
`;

const Media = styled(motion.div)`
  img {
    height: 100vh !important;
    object-fit: cover;
    object-position: top;
  }
`;

const Description = styled(motion.div)`
  margin: 5rem 0rem;
`;

export default ProjectDetails;
