import { AnimatePresence } from "framer-motion";
import { Switch, Route, useLocation } from "react-router";
import Footer from "./components/Footer";
import GlobalStyle from "./components/GlobalStyle";
import Nav from "./components/Nav";
import ScrollTop from "./components/scrollTop";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import Portfolio from "./pages/Portfolio";
import Blog from "./pages/Blog";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


function App() {
  const location = useLocation();

  return (
    <div className="App">
      <GlobalStyle />
      <ScrollTop />
      <Nav />
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route path="/" exact>
            <AboutUs />
          </Route>
          <Route path={["/portfolio/:slug", "/portfolio"]}> 
            <Portfolio />
          </Route>
          <Route path="/blog" exact>
            <Blog />
          </Route> 
          <Route path="/contact">
            <Contact />
          </Route>
        </Switch>
      </AnimatePresence>
      <Footer />
      <ToastContainer position="top-left"
            autoClose={6000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover/>
    </div>
  );
}

export default App;
