import React from "react";
import greyback from "../img/grey-back.jpg";
// Styled
import { About, Description, Image, Hide } from "../styles.js";
// Framer motion
import { motion } from "framer-motion";
import { titleAnim, fade, photoAnim } from "../animation";
import Wave from "./Wave";
import styled from "styled-components";
import { Link } from "react-router-dom";

const AboutSection = () => {
  return (
    <AboutStyle>
      <Description>
        <motion.div>
          <Hide>
            <motion.h2 variants={titleAnim}>Avec <strong>Axoum Dev</strong>,</motion.h2>
          </Hide>
          <Hide>
            <motion.h2 variants={titleAnim}>
              Réalisez <span>votre projet</span> web ou mobile <span>fiable et sur-mesure</span>
            </motion.h2>
          </Hide>
        </motion.div>
        <motion.p variants={fade}>
          Contactez-nous sans plus attendre pour un service fiable et de haute qualité
        </motion.p>

        <Link to="/contact">
          <motion.button variants={fade}> Contactez-nous !</motion.button>
        </Link>

      </Description>

      <Image>
        <motion.img variants={photoAnim} src="https://blush.design/api/download?shareUri=7QP8QbZCijlfjQQP&c=Bottom_0%7E393f82-0.1%7Effa434-0.2%7E393f82-0.3%7Effa434_Hair_0%7E181658-0.1%7E181658-0.2%7Eb58143-0.3%7Ee8e1e1_Skin_0%7Eb28b67-0.1%7E915b3c-0.2%7Eeac7a8-0.3%7Eb28b67_Top_0%7E89c5cc-0.1%7E89c5cc-0.2%7E43d26c-0.3%7E43d26c&w=800&h=800&fm=png" alt="Accueil Axoum" />
      </Image>
      <Wave />
    </AboutStyle>
  );
};

const AboutStyle = styled(About)`
  background: url(${greyback});
  z-index: 0;
  color: #000;
  p, button {
    color: #000;
  }
`;

export default AboutSection;
