import React from "react";
import styled from "styled-components";
import { About } from "../styles";
import Toggle from "./Toggle";
import { AnimateSharedLayout } from "framer-motion";
import { scrollReveal } from "../animation";

import { UseScroll } from "./UseScroll";
import mehome from "../img/me-home.jpg";

const FaqSection = () => {
  const [element, controls] = UseScroll();

  return (
    <Faq
      variants={scrollReveal}
      animate={controls}
      ref={element}
      initial="hidden"
    >
      <h2>
        Vous vous demandez <span>peut-être...</span> 
      </h2>
      <AnimateSharedLayout>
        <Toggle title="Quel est mon parcours et mon background ?">
          <div className="answer">
            <div> <img src={mehome} alt="Assane Thione BA" /> </div>
            <div>
              <p>Mon nom est Assane Thione BA. </p>
              <p>Je suis diplômé en Génie Logiciel en fin 2014 et posséde également un titre de Développeur d'Applications enregistré au RNCP-France (Répertoire National des Certifications Professionnelles - code code 27099 ).</p>
              <p>Je réalise des sites et applications pour divers clients (ONG, particuliers, etc) depuis 5 ans.</p>
              <p> Par ailleurs, je suis actif dans le domaine associatif et suis sensible à tout ce qui touche aux TIC, à l'éducation et à l'environnement.</p>
              <p>Pour plus de détails vous pouvez visiter mon <a href="https://www.linkedin.com/in/assane-ba/" alt="LinkedIn-link" target="_blank" rel="noreferrer">profil LinkedIn</a>
              </p>
            </div>
          </div>
        </Toggle>
        <Toggle title="Pourquoi me confier votre projet ?">
          <div className="answer">
            <p>
              Capitalisant plus de 6 années d'expériences, j’ai déjà eu l'occasion d’exercer des missions impliquant des développements sur une architecture orientée objet avec des langages tels que PHP/Symfony, Javascript, REACT. J'ai notamment eu l'opportunité de créer plusieurs sites web pour des organisations et des particuliers. J’ai contribué à la mise en place et à l’amélioration de solutions techniques adaptées à leurs besoins. Grâce à ces expériences, j'ai pu développer des compétences en développement d’applications web depuis la phase de recueil des besoins jusqu’à la phase de déploiement et de maintenance des systèmes. 
              </p>
              <p>
              Mon parcours m'a permis de développer un très bon esprit d’analyse et de synthèse. Je possède une réelle aptitude à m'intégrer et à travailler avec des équipes pluridisciplinaires et multiculturelles. Partageant des valeurs de respect, d’exigence et d’excellence, mes compétences techniques alliées à mes qualités personnelles sauront faire écho à vos attentes pour mener à bien votre projet.
            </p>
          </div>
        </Toggle>
        <Toggle title="Témoignages des clients et collaborateurs">
          <div className="answer">
            <div>
              <h3>Benoit Lanselle</h3>
              <h5>Directeur Technique / Lead dev back</h5>
              <p>"J'ai encadré Assane en tant mentor dans le cadre de sa formation Openclassrooms. J'ai été impressionné par ses compétences et sa capacité d'apprentissage qui est digne d'un développeur fait. je le recommande chaudement !"</p>
            </div>
             <div>
              <h3>Philippe Beck</h3>
              <h5>CEO DevsAgency / Lead Developer</h5>
              <p>"Assane m'a montré durant toute une année d'accompagnement sa forte volonté d'apprentissage & une bonne capacité de réflexion, notamment au travers de questions pertinentes & d'idées efficaces ; de plus, d'autres compétences lui permettent d'être un atout majeur, en particulier de multiples soft skills, ainsi que ses compétences en PHP & Symfony. Je le recommande vivement à toutes les entreprises ! ."</p>
            </div>
            <div>
              <h3>Patrice Radon</h3>
              <h5>Directeur des Ressources Humaines</h5>
              <p>"J'ai accompagné Assane en qualité de coach. J'ai apprécié ses qualités de persévérance et d'écoute. Sa curiosité et son intérêt pour les nouvelles technologies lui permettront de mobiliser tout son potentiel pour réaliser ses projets professionnels."</p>
            </div>
          </div>
        </Toggle>
        <Toggle title="Comment me contacter ?">
          <div className="answer">
            <p>Email : <a href="mailto:assanetb@gmail.com">assanetb@gmail.com</a></p>
            <p>WhatsApp • Signal • Telegram : <strong>+221 77 478 56 36</strong> </p>
          </div>
        </Toggle>
      </AnimateSharedLayout>
    </Faq>
  );
};

const Faq = styled(About)`
  background: #1d1b1b;
  display: block;
  span {
    display: block;
  }
  h2 {
    padding-bottom: 2rem;
    font-weight: lighter;
  }
  .faq-line {
    background: 2rem #fff;
    height: 0.2rem;
    margin: 2rem 0rem;
    width: 100%;
  }
  .arrow {
      border: solid white;
      border-width: 0 3px 3px 0;
      padding: 13px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
  }
  

  .question {
    padding: 3rem 0rem;
    cursor: pointer;
  }
  .answer {
    padding: 2rem 0rem;
    margin: 2rem 3rem ;
    p {
      padding: 1rem 3rem;
    }
    p strong, h5 {
      color: #E38C4D;
    }
    display: flex;
    justify-content: space-between;
    img {
      height: 50vh;
    }

    @media(max-width: 688px) {
      flex-direction: column;
    }
  }
`;

export default FaqSection;
