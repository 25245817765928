import React from "react";
// Import icons
import devweb from "../img/dev-web-mobile.svg";
import siteweb from "../img/creation-de-sites-web.svg";
import depannage from "../img/depannage.svg";
import conception from "../img/conception-tech.svg";
//Styles
import { About, Description } from "../styles";
import styled from "styled-components";

import { scrollReveal } from "../animation";
import { UseScroll } from "./UseScroll";
import { Link } from "react-router-dom";

const ServicesSection = () => {
  const [element, controls] = UseScroll();

  return (
    <Services
      variants={scrollReveal}
      animate={controls}
      initial="hidden"
      ref={element}
    >
      <Description>
        <h2>
          Un service de <span>haute qualité</span>
        </h2>
        <Cards>
          <Card>
            <div className="icon">
              <img src={devweb} alt="devweb" />
              <h3>Développement web et mobile</h3>
            </div>
            <p>
              Création d'applications web et mobile professionnelles, d'API,
              etc.
            </p>
          </Card>

          <Card>
            <div className="icon">
              <img src={siteweb} alt="siteweb" />
              <h3>Sites web</h3>
            </div>
            <p>
              Création de sites vitrine • sites e-commerce • sites web sur-mesure • approche mobile-first.
            </p>
          </Card>

          <Card>
            <div className="icon">
              <img src={depannage} alt="depannage" />
              <h3>Dépannage / maintenance</h3>
            </div>
            <p>
              Dépannage et maintenance de sites sous Wordpress • réalisation des
              tests unitaires et fonctionnelles • audit de qualité et de
              performance d'applications.
            </p>
          </Card>

          <Card>
            <div className="icon">
              <img src={conception} alt="conception" />
              <h3>Conception technique</h3>
            </div>
            <p>
              Conception technique d'applications suivant un cahier des charges •
              rédaction de cahiers de charges, de documentation technique
              d'applications pour développeurs et utilisateurs.
            </p>
          </Card>
          
          <Link to="/portfolio">
            <button className="btn-service">Voir mes réalisations</button>
          </Link>

        </Cards>
      </Description>
    </Services>
  );
};

const Services = styled(About)`
  h2 {
    padding-bottom: 5rem;
  }

  p {
    color: white;
    padding: 2rem 0rem 4rem 0rem;
    font-size: 1.5rem;
  }

  .btn-service {
    margin: 5rem 0 3rem 0;
  }

`;

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  //@media (max-width: 1200px) {
    justify-content: space-around;
  //}
  div:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.3);
  }
  

`;

const Card = styled.div`

  background: rgba(227, 139, 77, 0.3);
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border: solid rgba(22, 20, 20, 0.171) 2px;
  margin-bottom: 2rem;
  padding: 2px 10px;
  flex-basis: 40rem;
  .icon {
    display: flex;
    align-items: center;
    h3 {
      margin-left: 1rem;
      background: rgba(227, 139, 77, 0.589);
      color: #ffffff;
      padding: 1rem;
    }
  }
`;

export default ServicesSection;
